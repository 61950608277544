import React from 'react';
import Redirect from '../lib/redirect';
import '../styles/pages/404.scss';

const PageNotFound = () => {
  const url = typeof window !== "undefined" ? window.location.href : "";

  if (url.includes("home")) {
    return <Redirect to="/" />;
  }
  return (
    <div className="page-not-found">
      <div className="page-not-found__inner">
        <div className="page-not-found__card">
          <h3 className="page-not-found__card__404">
            404
          </h3>
          <h3 className="page-not-found__card__text">
            Dangit! Looks like this page doesn't exist {`:(`}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PageNotFound);
